<template>
  <span>
    <div id="main"
         style="height:500px;width:60%"></div>
  </span>

</template>

<script>

import * as echarts from "echarts";
import dataTool from "echarts/extension/dataTool";
import { getClassScores } from '@/api/analyze.js'

export default {
  name: "hometu",
  async mounted () {
    let params = {
      testPaperId: this.$route.query.test_paper_id
    }
    const { data: datas } = await getClassScores(params)
    var data = [];
    var values = [];
    var xAxisDatas = []

    datas.forEach(item => {
      values.push(item.scores)
      xAxisDatas.push(item.className)
    });

    data.push(echarts.dataTool.prepareBoxplotData(values));

    //以此类推,想画几个盒子就直接加数据和118行所说的画下去就好了
    let bar = echarts.init(document.getElementById('main'));
    let option = {
      legend: {
        y: '10%',
      },
      tooltip: {
        trigger: 'item',
        axisPointer: {
          type: 'shadow'
        }
      },
      grid: {
        left: '10%',
        top: '20%',
        right: '10%',
        bottom: '15%'
      },
      xAxis: {
        type: 'category',
        data: xAxisDatas,
        boundaryGap: true,
        nameGap: 30,
        splitArea: {
          show: true
        },
        axisLabel: {
          formatter: '{value}'
        },
        splitLine: {
          show: false
        }
      },
      yAxis: {
        /*min:-400,//可自定义y轴的最大和最小值
        max:600,*/
        type: 'value',
        name: '分数',
        splitArea: {
          show: false
        }
      },
      series: [
        {
          name: '',
          type: 'boxplot',
          data: data[0].boxData,//data[0].boxData代表的是data中红色盒子的数据
          tooltip: { formatter: formatter }
        },

      ]
    };


    function formatter (param) {
      return [
        '类别名称: ' + param.name,
        '下四分位数: ' + param.data[1],
        '中位数: ' + param.data[2],
        '上四分位数: ' + param.data[3],
        '下边界: ' + param.data[4]
      ].join('<br/>')
    }
    bar.setOption(option);
  },

}
</script>

<style scoped>
</style>

